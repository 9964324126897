import { supportAPI } from '@/apps/support/api';
import HomeIcon from '@/assets/img/home-icon.svg';
import ChatIcon from '@/assets/img/chat-icon.svg';
import { markRaw } from 'vue';

const initialState = () => ({
  menu: [
    {
      id: 'chat',
      title: 'Чат 2',
      type: 'header',
      icon: markRaw(ChatIcon),
      nodes: [
        {
          id: 'chat/company',
          title: 'Компании',
          type: 'subheader',
          key: "chat-company",
          meta: {
            forceActive: true,
          },
          link: '/chat/company'
        },
        {
          id: 'chat/customer',
          title: 'Покупатели',
          type: 'subheader',
          key: "chat-customer",
          meta: {
            unassigned_count: '',
          },
          link: '/chat/customer',
        },
        {
          id: 'chat/employee',
          title: 'Сотрудники',
          type: 'subheader',
          key: "chat-employee",
          meta: {
            unassigned_count: '',
          },
          link: '/chat/employee',
        },
      ],
    }
  ]
});

const state = initialState();

const getters = {
  menu: (state) => state.menu,
};

const actions = {
  findById: ({ getters }, opts = {}) => {
    const { id } = opts;

    const recur = (target) => {
      {
        const found = target.find((n) => n.id === id);
        if (found) return found;
      }

      {
        const [found] = target
          .map((n) => {
            if (n.nodes) return recur(n.nodes);
          })
          .filter((v) => v);
        if (found) return found;
      }
    };

    const r = recur(getters.menu);

    return r;
  },

  createFunnelNodes: async ({ dispatch }) => {
    let count = await dispatch('unassignedChatCount');
    count = count.funnels.slice(1, count.funnels.length);
    const items = (await supportAPI.getAvailableFunnels()).map(({ id, name }) => {
      return {
        id: `support/company/funnel/${id}`,
        external_id: id,
        title: name,
        type: 'button',
        meta: {
          unassigned_count: count[id - 1],
        },
        link: { name: 'support$support-company', params: { funnel: id } },
      };
    });

    return items;
  },

  updateUnassignedChatCount: async ({ state, dispatch }, funnel) => {
    const r = await dispatch('findById', { id: 'support/company' });
    if (!r?.nodes) return;
    r.nodes.forEach((val, key) => {
      if (val.external_id == funnel) {
        r.nodes[key].meta.unassigned_count -= 1;
      }
    });
  },

  fetchFunnels: async ({ dispatch }, opts = {}) => {
    const { id } = opts;

    try {
      const r = await dispatch('findById', { id });

      if (r.nodes) return true;
      r.nodes = await dispatch('createFunnelNodes');

      return true;
    } catch (error) {
      return false;
    }
  },

  unassignedChatCount: async ({ state }) => {
    let chatsCount;
    try {
      chatsCount = await supportAPI.getUnassignedCount();
    } catch (error) {
      console.log(error);
    }

    const supportMenu = state.menu.filter((val) => {
      return val.id === 'support';
    })[0];

    Array.isArray(supportMenu.nodes) &&
      supportMenu.nodes.forEach((val, key) => {
        if (val.id === 'support/company') {
          supportMenu.nodes[key].meta.unassigned_count = chatsCount.funnels.reduce((accumulator, value) => accumulator + value, 0);
        }
        if (val.id === 'support/customer') {
          supportMenu.nodes[key].meta.unassigned_count = chatsCount.customer;
        }
        if (val.id === 'support/employee') {
          supportMenu.nodes[key].meta.unassigned_count = chatsCount.employee;
        }
      });
    return chatsCount;
  },

  removeFromList: async ({ commit }) => {
    commit('_cleanThisMenu');
  },
};

const mutations = {
  _cleanThisMenu: (state) => {
    state.menu = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
