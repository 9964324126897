/* eslint-disable no-shadow, no-param-reassign, indent */

import * as fns from 'date-fns';
import { bus } from '@brskl/core';
import { supportAPI } from '@/apps/support/api';
import { mainAPI } from '@/core/api/main';
import { uniqBy, cloneDeep } from 'lodash';
import * as channel from '@/core/channel';
import { nanoid } from 'nanoid';
import { isBefore } from 'date-fns';
import router from '@/core/router';

const initialCommentState = () => ({
  input: {},
  items: {},
  meta: {},
  expand: false,
});

const initialCommentPromptState = () => ({
  show: false,
  promptInput: '',
  q: '',
  selected: null,
  selectedName: '',
  items: [],
});

const initialState = () => ({
  input: {},
  attachedFiles: {},
  loading: false,
  fetchInit: false,
  chats: {
    items: [],
    meta: {},
  },
  filters: {
    administrator_comment_id: null,
  },
  replyMessage: null,
  isResolved: true,
  nextChatId: null,
  messages: {},
  activeChatId: null,
  editMessages: {},
  type: null,
  funnel: null,
  status: 0,
  subscribedChats: {},

  messagesCount: {},

  lastReadMessageTimestamp: {},

  manualSwitch: false,

  comment: initialCommentState(),
  commentPrompt: initialCommentPromptState(),
  error: '',
});

const state = initialState();

const getters = {
  repliesMsg: (state) => state.replyMessage,
  isResolved: (state) => {
    return state.isResolved;
  },
  nextChatId: (state) => state.nextChatId,
  manualSwitch: (state) => state.manualSwitch,
  loading: (state) => state.loading,
  fetchInit: (state) => state.fetchInit,
  input: (state) => (id) => state.input[id],
  attachedFiles: (state) => (id) => state.attachedFiles[id] || [],
  editMessages: (state) => (chatId) => state.editMessages[chatId] || {},
  chats: (state) => {
    return state.chats.items;
  },
  hasChats: (_state, getters) => !!getters.chats.length,
  messages: (state) => state.messages,
  activeChatId: (state) => state.activeChatId,
  type: (state) => state.type,
  funnel: (state) => state.funnel,
  status: (state) => state.status,
  filters: (state) => state.filters,
  subscribedChats: (state) => state.subscribedChats,
  isChatRegisteredInSubscribers: (state) => (id) => typeof state.subscribedChats[id] === 'boolean',
  isChatInSubscribers: (state) => (id) => state.subscribedChats[id],
  messagesCountByChatId: (state) => (id) => state.messagesCount[id] || 0,
  lastReadMessageTimestampById: (state) => (id) => state.lastReadMessageTimestamp[id],
  isMessageRead: (_state, getters) => (chat_id, timestamp) => {
    const lastReadMessageTimestamp = getters.lastReadMessageTimestampById(chat_id);
    if (!lastReadMessageTimestamp) return false;
    return isBefore(new Date(timestamp), new Date(lastReadMessageTimestamp));
  },

  comment: (state) => state.comment,
  commentPrompt: (state) => state.commentPrompt,

  commentItemsByChatId: (state) => (id) => state.comment.items[id] || [],
  commentMetaByChatId: (state) => (id) => state.comment.meta[id] || {},
  commentInput: (state) => (id) => state.comment.input[id] || '',
  error: (state) => state.error,
};

const actions = {
  searchOfficeByName: async ({ commit, getters }, opts = {}) => {
    try {
      const response = await supportAPI.searchByName({ q: opts });
      return response;
    } catch (error) {
      console.error(error);
    }
  },

  fetchComments: async ({ commit, getters }, opts = {}) => {
    try {
      const { id = getters.activeChatId, reset = false } = opts;

      const items = getters.commentItemsByChatId(id);
      const meta = { ...getters.commentMetaByChatId(id) };

      if (reset) meta.page = 1;
      if (meta.total_pages && meta.page >= meta.total_pages) return;

      const response = await supportAPI.commentGetList({ chat_id: id });

      const newItems = reset ? response.items : [...items, ...response.items];

      commit('_setCommentItems', { id, value: newItems });
      commit('_setCommentMeta', { id, value: response.meta });
    } catch (error) {
      console.error(error);
    }
  },

  resolveCommentBox: ({ commit }, value) => {
    commit('_setCommentResolved', value);
  },

  replyMessage: ({ commit }, value) => {
    commit('_setReplyMessageData', value);
  },

  sendReplyMessage: async ({ commit, dispatch, getters }) => {
    dispatch('setLoadingAction', true);
    const id = getters.activeChatId;
    const reply = getters.repliesMsg;
    const msg = getters.input(id);
    const payload = {
      reply_to_id: reply.id,
      text: msg,
      chat_id: reply.chat.id,
    };
    dispatch('setInputAction', { id, message: '' });
    commit('_setReplyMessageData', null);
    await supportAPI.replyToMessage(payload);
    if (getters.isChatRegisteredInSubscribers(id) && !getters.isChatInSubscribers(id)) {
      await dispatch('setSubscribedChat', { id, value: true });
    }
    dispatch('setLoadingAction', false);
  },

  updateComment: async ({ getters, commit }, opts = {}) => {
    const { id, chatId = getters.activeChatId, is_checked, text } = opts;

    const comment = getters.commentItemsByChatId(chatId).find((c) => c.id === id);

    if (!comment) return;

    const payload = {
      id,
      chat_id: chatId,
      is_read: comment.is_read,
      is_checked: Number(typeof is_checked === 'boolean' ? is_checked : comment.is_checked),
      text: text || comment.text,
    };

    const response = await supportAPI.commentUpdate(payload);

    const items = getters.commentItemsByChatId(chatId);

    const newItems = items.map((i) => {
      if (i.id === id) return response;
      return i;
    });

    commit('_setCommentItems', { id: chatId, value: newItems });
    commit('_updateChats', { id: chatId, value: newItems });
  },

  sendComment: async ({ commit, getters }, opts = {}) => {
    try {
      const { id = getters.activeChatId, parentId = 0, text, administratorId } = opts;

      if (!text) return;

      const chat = getters.chats.find((c) => c.id === id);

      const payload = {
        chat_id: id,
        text,
        parent_id: parentId,
        administrator_id: administratorId || chat.administrator_id || 0,
      };

      const response = await supportAPI.commentSend(payload);

      const items = getters.commentItemsByChatId(id);

      commit('_setCommentItems', { id, value: [response, ...items] });
    } catch (error) {
      console.error(error);
    }
  },

  setCommentExpand: ({ commit }, value) => {
    commit('_setCommentExpand', value);
  },

  setCommentInput: ({ commit, getters }, opts = {}) => {
    const { id = getters.activeChatId, value } = opts;
    commit('_setCommentInput', { id, value });
  },

  flushCommentPrompt: ({ commit }) => {
    commit('_flushCommentPrompt');
  },

  setCommentPromptShow: ({ commit, dispatch }, value) => {
    commit('_setCommentPromptShow', value);
    if (!value) dispatch('flushCommentPrompt');
  },

  setCommentPromptInput: ({ commit }, value) => {
    commit('_setCommentPromptInput', value);
  },

  setCommentPromptQ: ({ commit }, value) => {
    commit('_setCommentPromptQ', value);
  },

  setCommentPromptItems: ({ commit }, value) => {
    commit('_setCommentPromptItems', value);
  },

  setCommentPromptSelected: ({ commit }, value) => {
    commit('_setCommentPromptSelected', value);
  },
  removeCommentPromptSelected: ({ commit }, value) => {
    commit('_removeCommentPromptSelected');
  },
  setFilters: ({ commit }, value) => {
    commit('_setFilters', value);
  },

  updateMessagesCount: async ({ commit, rootGetters }, opts = {}) => {
    const { chat_id } = opts;
    const channelInstance = await channel.get();
    const evName = `updateMessagesCount:${nanoid()}`;

    const handler = (data) => {
      commit('_updateMessagesCount', { id: chat_id, value: data.payload });
      bus.off(evName, handler);
    };

    bus.on(evName, handler);

    const { id: account_id } = rootGetters['shared$profile/profile'];

    channelInstance.send(
      JSON.stringify({
        client: 'web',
        action: 'chatGetUnreadMessageCount',
        account_id,
        chat_id,
        reply_to: evName,
      }),
    );
  },

  flush: ({ commit }) => {
    commit('_flush');
  },

  setFetchInit: ({ commit }, value) => {
    commit('_setFetchInit', value);
  },

  setType: ({ commit }, value) => {
    commit('_setType', value);
  },

  setFunnel: ({ commit }, value) => {
    commit('_setFunnel', value);
  },

  assignChat: async ({ commit, getters, dispatch }, opts = {}) => {
    const { id, status, route, is_notification = 0 } = opts;
    const { activeChatId } = getters;
    const payload = {
      id,
      status,
      ...(is_notification && { is_notification }),
    };
    try {
      commit('setLoading', true);
      await supportAPI.setChatStatus(payload);
      if (id === activeChatId) {
        await dispatch('setStatus', { force: true, status });
      } else if (status === 1) {
        await router.replace({ name: route.name, params: { ...route.params, chat: id } });
        await dispatch('setStatus', { force: true, status });
      } else {
        commit('_removeChat', id);
      }
    } catch (error) {
      console.error(error);
    } finally {
      commit('setLoading', false);
    }
  },

  setStatus: async ({ commit, dispatch, getters }, opts = {}) => {
    const { status, flush = true, force = false, manual = false } = opts;
    // TODO: maybe delete in future
    if (status === getters.status) return;

    if (!flush) return commit('_setStatus', status);

    if (!force && getters.loading) return;

    const type = getters.type;
    const funnel = getters.funnel;
    // const filters = getters.filters;

    await dispatch('setLoadingAction', true);
    await dispatch('flush');
    commit('_setStatus', status);
    await dispatch('setFunnel', funnel);
    // await dispatch('setFilters', filters);
    if (manual) commit('_setManualSwitch', manual);
    await dispatch('setType', type);
  },

  setLoadingAction: ({ commit }, value) => {
    commit('setLoading', value);
  },

  setEditMessages: ({ commit }, { id, message }) => {
    commit('_setEditMessages', { id, message });
  },

  setAttachedFiles: ({ commit }, { id, value }) => {
    commit('_setAttachedFiles', { id, value });
  },

  removeAttachedFile: async ({ getters, dispatch }, { id, value }) => {
    const filteredFiles = getters.attachedFiles(id).filter((f) => f.src.name !== value.src.name);

    await dispatch('setAttachedFiles', { id, value: filteredFiles });
  },

  setInputAction: ({ commit }, { id, message }) => {
    commit('setInput', { id, message });
  },

  setErrorStatus: ({ commit }, { error }) => {
    commit('setError', { error });
  },

  setMessagesAction: ({ commit }, { chat, messages }) => {
    commit('setMessages', { chat, messages });
  },

  setSubscribedChat: ({ commit }, { id, value }) => {
    commit('_setSubscribedChat', { id, value });
  },

  fetchChatsAction: async ({ state, getters, commit, dispatch }, opts = {}) => {
    const { force = false, funnel = getters.funnel, filters = getters.filters } = opts;
    if (!force && getters.loading) return;

    const { meta } = state.chats;
    let result;

    try {
      dispatch('setLoadingAction', true);

      if (!meta.page)
        result = await supportAPI.getChatList({
          type: getters.type,
          funnel,
          filters,
          status: getters.status,
        });
      else if (meta.page >= meta.total_pages) return;
      else
        result = await supportAPI.getChatList({
          ...meta,
          page: meta.page + 1,
          type: getters.type,
          filters,
          funnel,
          status: getters.status,
        });
      commit('setChats', result);

      const { items } = result;

      items.forEach((c) => dispatch('updateMessagesCount', { chat_id: c.id }));

      if (!getters.manualSwitch) {
        if (!getters.chats.length) {
          if (getters.status === 0) {
            await dispatch('setStatus', { status: 1, force: true });
          } else if (getters.status === 1) {
            await dispatch('setStatus', { status: -1, force: true });
          }
        }
      }

      commit('_setManualSwitch', false);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch('setLoadingAction', false);
      dispatch('setFetchInit', true);
    }
  },
  removeAllChats: ({ commit }) => {
    commit('_removeAllChats');
  },

  fetchChatAction: async ({ getters, commit, dispatch }, opts = {}) => {
    const { id, force = false } = opts;

    if (!force && getters.loading) return;

    try {
      dispatch('setLoadingAction', true);
      const chat = await supportAPI.getChatById({ id });
      if (chat && chat.status === getters.status) {
        commit('addChat', chat);
        dispatch('updateMessagesCount', { chat_id: chat.id });
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch('setLoadingAction', false);
    }
  },

  sendMessageAction: async ({ dispatch, getters }, opts = {}) => {
    if (getters.loading) return;

    const id = getters.activeChatId;
    const message = getters.input(id);
    const editMessage = getters.editMessages(id);
    const attachments = getters.attachedFiles(id);

    const isEdit = !!editMessage.id;

    const chat = getters.chats.find((c) => c.id === id);

    if (!chat.administrator_id) {
      await dispatch('assignChat', { id, status: 1, route: router.currentRoute });
    }

    try {
      dispatch('setErrorStatus', {error: ''});
      dispatch('setLoadingAction', true);

      if (isEdit) {
        if (!editMessage.text) return;

        dispatch('setEditMessages', { id });

        await supportAPI.editMessage({
          chat_id: id,
          chat_message_id: editMessage.id,
          text: editMessage.text,
        });
      } else {
        if (!message) {
          if (!attachments.length) return;
        }

        dispatch('setInputAction', { id, message: '' });

        if (message) {
          await supportAPI.sendMessage({
            chat_id: id,
            text: message,
          });
        }

        if (attachments.length) {
          for (const file of attachments) {
            const { preview } = file;

            try {
              await supportAPI.uploadFile({ file: preview.base64, chat_id: id });
              await dispatch('removeAttachedFile', { id, value: file });
            } catch (e) {
              console.error(e);
            }
          }
        }
      }

      if (getters.isChatRegisteredInSubscribers(id) && !getters.isChatInSubscribers(id)) {
        await dispatch('setSubscribedChat', { id, value: true });
      }
    } catch (error) {
      console.error(error);

      if (error?.error?.code == 1) {
        dispatch('setInputAction', { id, message });
        dispatch('setErrorStatus', {error: 'Ошибка при отправке сообщения, повторите попытку'});
      }
    } finally {
      dispatch('setLoadingAction', false);
    }
  },

  handleChatStatusChanged: async ({ commit, getters, rootGetters }, value) => {
    try {
      const { administrator_id, chat_id } = value;
      const { id } = rootGetters['shared$profile/profile'];

      if (id === administrator_id) return;

      if (Number(getters.status) !== -1) {
        if (chat_id === getters.activeChatId) commit('setActiveChatId', null);
        commit('_removeChat', chat_id);
      } else {
        commit('_mutateChat', { id: value.chat_id, value });
      }
    } catch (error) {
      console.error(error);
    }
  },

  maybeAddChatMessageNotification: async ({ dispatch, getters, rootGetters }, message) => {
    const funnel = getters.funnel === undefined ? null : Number(getters.funnel);
    const profile = rootGetters['shared$profile/profile'];

    const { chat, sender } = message;

    const showMessage = () =>
      dispatch('core$notifications/add', { value: message, type: 'chat-message' }, { root: true });

    if (sender.id === profile.id) return;
    if (sender.alias === 'administrator') return;
    if (chat.id === getters.activeChatId) return;

    showMessage();

    // if (chat.administrator_id && chat.administrator_id !== profile.id) return;

    // if (chat.type === getters.type) {
    //   if (chat.funnel === null) {
    //     if (getters.status === -1 || getters.status === chat.status) {
    //       // TODO: play song
    //       console.log('BING');
    //       return;
    //     }

    //     // TODO: play song and show message
    //     console.log('BING');
    //     return showMessage();
    //   }

    //   if (funnel === -1 && getters.status === -1) {
    //     // TODO: play song
    //     return console.log('BING');
    //   }

    //   if (funnel === -1 && chat.status !== getters.status) {
    //     // TODO: play song and show message
    //     console.log('BING');
    //     return showMessage();
    //   }

    //   if (funnel !== getters.funnel) {
    //     // TODO: play song and show message
    //     console.log('BING');
    //     return showMessage();
    //   }

    //   if (getters.status === -1 || getters.status === chat.status) {
    //     // TODO: play song
    //     return console.log('BING');
    //   }

    //   // TODO: play song and show message
    //   console.log('BING');
    //   return showMessage();
    // }

    // // TODO: play song and show message
    // console.log('BING');
    // showMessage();
  },

  /**
   * chat: {items: []}
   * messages: {id: {items: []}}
   */
  addMessageAction: async ({ state, commit, dispatch, getters, rootGetters }, value) => {
    const funnel = getters.funnel === undefined ? null : Number(getters.funnel);
    const profile = rootGetters['shared$profile/profile'];
    if (!value?.chat?.id) return;

    {
      const { administrator_id, funnel, type, id } = value.chat;

      if (administrator_id === undefined || funnel === undefined || type === undefined) {
        const response = await mainAPI.getChatMeta({ id });
        value.chat = { ...value.chat, ...response };
      }
      if (value.currentOpenedChatId === value.chat_id) {
        await supportAPI.markMessageAsRead(value);
      }
    }

    await dispatch('maybeAddChatMessageNotification', value);

    if (value.chat.funnel !== funnel) {
      if (funnel !== -1) return;
    }

    if (getters.type !== value.chat.type) {
      return;
    }

    if (getters.status !== value.chat.status) {
      if (getters.status >= 0) return;
    }

    if (value.chat.administrator_id && value.chat.administrator_id !== profile.id) {
      if (getters.status >= 0) return;
    }

    const getChat = async () => {
      try {
        const chat = await supportAPI.getChatById({ id: value.chat_id });

        commit('addChat', chat);

        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    };

    let chat = state.chats.items.find((c) => c.id == value.chat_id);

    // TODO: consider to get chat and only then add message;
    if (!chat) {
      const isAdded = await getChat();
      if (!isAdded) return;
    }

    chat = state.chats.items.find((c) => c.id == value.chat_id);

    if (!state.messages[chat.id]) {
      // console.log('try to fetch messages for chat', chat.id);

      try {
        const messages = await supportAPI.getChatMessages({ chat_id: chat.id });

        await dispatch('setMessagesAction', { chat: chat.id, messages });
      } catch (error) {
        console.error(error);
        return;
      }
    }

    const newMessageItems = [value, ...state.messages[chat.id].items];

    const payload = {
      chat: chat.id,
      messages: { meta: state.messages[chat.id].meta, items: newMessageItems },
    };

    commit('setMessages', payload);
    bus.emit('support$message/add', value);
    dispatch('updateMessagesCount', { chat_id: chat.id });
  },

  markMessageAsReadAction: async ({ dispatch, commit, getters, rootGetters }, { chat_id, id, timestamp_inserting }) => {
    try {
      const currentTimestamp = new Date(timestamp_inserting);
      const lastTimestamp = getters.lastReadMessageTimestampById(chat_id);

      if (lastTimestamp) {
        const isOlder = isBefore(new Date(currentTimestamp), new Date(lastTimestamp));
        if (isOlder) return;
      }

      const channelInstance = await channel.get();
      const evName = `markMessageAsReadAction:${nanoid()}`;

      const handler = ({ payload }) => {
        bus.off(evName, handler);

        if (payload) {
          commit('_setLastReadMessageTimestamp', { id: chat_id, value: timestamp_inserting });
          dispatch('updateMessagesCount', { chat_id });
        }
      };

      const { id: account_id } = rootGetters['shared$profile/profile'];

      bus.on(evName, handler);

      channelInstance.send(
        JSON.stringify({
          client: 'web',
          action: 'chatMarkMessageAsRead',
          account_id: account_id,
          chat_id: chat_id,
          message_id: id,
          reply_to: evName,
        }),
      );
    } catch (error) {
      console.error(error);
    }
  },

  setActiveChatIdAction: ({ commit }, value) => {
    commit('setActiveChatId', value);
  },
};

const mutations = {
  _flush: (state) => {
    Object.assign(state, initialState());
  },

  _setCommentItems: (state, { id, value }) => {
    state.comment.items[id] = value;
  },

  _setCommentMeta: (state, { id, value }) => {
    state.comment.meta[id] = value;
  },

  _setCommentInput: (state, { id, value }) => {
    state.comment.input[id] = value;
  },

  _setCommentExpand: (state, value) => {
    state.comment.expand = value;
  },

  _flushCommentPrompt: (state) => {
    state.commentPrompt = initialCommentPromptState();
  },

  _setCommentPromptInput: (state, value) => {
    state.commentPrompt.input = value;
  },

  _setCommentPromptQ: (state, value) => {
    state.commentPrompt.q = value;
  },

  _setCommentPromptItems: (state, value) => {
    state.commentPrompt.items = value;
  },

  _setCommentPromptSelected: (state, value) => {
    state.commentPrompt.selected = value.id;
    state.commentPrompt.selectedName = value.name;
  },

  _removeCommentPromptSelected: (state, value) => {
    state.commentPrompt.selected = null;
    state.commentPrompt.selectedName = '';
  },
  _setFilters: (state, value) => {
    state.filters.administrator_comment_id = value.id;
  },

  _setCommentPromptShow: (state, value) => {
    state.commentPrompt.show = value;
  },
  _setCommentResolved: (state, value) => {
    state.isResolved = value;
  },

  _mutateChat: (state, { id, value }) => {
    const index = state.chats.items.findIndex((i) => i.id === id);

    if (~index) {
      state.chats.items[index] = { ...state.chats.items[index], ...value };
    }
  },

  _updateMessagesCount: (state, { id, value }) => {
    state.messagesCount[id] = value;
  },

  _setLastReadMessageTimestamp: (state, { id, value }) => {
    state.lastReadMessageTimestamp[id] = value;
  },

  _setType: (state, value) => {
    state.type = value;
  },

  _setStatus: (state, value) => {
    state.status = value;
  },

  _setFetchInit: (state, value) => {
    state.fetchInit = value;
  },

  _setFunnel: (state, value) => {
    state.funnel = value;
  },

  _setSubscribedChat: (state, { id, value }) => {
    state.subscribedChats = { ...state.subscribedChats, [id]: value };
  },

  _setEditMessages: (state, { id, message }) => {
    state.editMessages = { ...state.editMessages, [id]: message ? cloneDeep(message) : {} };
  },

  _setAttachedFiles: (state, { id, value }) => {
    state.attachedFiles = { ...state.attachedFiles, [id]: value };
  },

  setLoading: (state, value) => {
    state.loading = value;
  },

  setInput: (state, { id, message }) => {
    state.input = { ...state.input, [id]: message };
  },

  setError: (state, {error}) => {
    state.error = error
  },

  _setReplyMessageData: (state, value) => {
    state.replyMessage = value;
  },

  setChats: (state, { items, meta }) => {
    let allChats = [...state.chats.items, ...items];
    const uniqueIds = new Set(allChats.map((c) => c.id));
    const uniqueChats = [...uniqueIds].map((id) => allChats.find((chat) => chat.id === id));

    state.chats = { items: uniqueChats, meta };
  },

  addChat: (state, value) => {
    const allChats = [...state.chats.items, value];
    const uniqueIds = new Set(allChats.map((c) => c.id));
    const uniqueChats = [...uniqueIds].map((id) => allChats.find((chat) => chat.id === id));

    state.chats = { items: uniqueChats, meta: state.chats.meta };
  },

  _removeChat: (state, value) => {
    const updatedChats = state.chats.items.filter((c) => c.id !== value);
    state.chats = { items: updatedChats, meta: state.chats.meta };
  },

  _removeAllChats: (state) => {
    state.chats.items = [];
    state.chats.meta = {};
  },

  setMessages: (state, { chat, messages }) => {
    const { items, meta } = messages;

    const targetItems = state.messages[chat] ? uniqBy([...items, ...state.messages[chat].items], 'id') : items;

    const newItems = targetItems.sort((m1, m2) => fns.compareAsc(new Date(m1.created_at), new Date(m2.created_at)));

    state.messages = { ...state.messages, [chat]: { items: newItems, meta } };
  },

  setActiveChatId: (state, value) => {
    state.activeChatId = value;
  },

  _setManualSwitch: (state, value) => {
    state.manualSwitch = value;
  },

  _updateChats: (state, { id, value }) => {
    if (state.status === -2) {
      state.chats.items = state.chats.items.filter((val, key) => {
        if (val.id === id) {
          state.comment.items[state.chats.items[key].id] = [];
          state.nextChatId = state.chats.items[key + 1];
        }
        return val.id !== id;
      });
    } else {
      state.chats.items.filter((val, key) => {
        if (val.id === id) {
          state.comment.items[state.chats.items[key].id] = [];
          state.chats.items[key].last_comment = null;
        }
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
