import { busenessSectionsApi } from '@/apps/business-sections/api';

const initialState = () => ({

// Items
  searchItemsTableColumns: [
    {
      name: 'checkbox',
      disableClickEvent: true,
    },
    {
      name: 'id',
      label: 'ID',
      removable: false,
    },
    {
      name: 'name',
      label: 'Название товара',
      removable: false,
    },
    {
      name: 'barcode',
      label: 'Штрихкод',
      removable: false,
    },
    {
      name: 'price',
      label: 'Цена',
      removable: false,
    },
  ],

  itemsTableColumns: [
    {
      name: 'checkbox',
      disableClickEvent: true,
    },
    {
      name: 'id',
      label: 'ID',
    },
    {
      name: 'name',
      label: 'Название товара',
    },
    {
      name: 'barcode',
      label: 'ШК товара',
    },
    {
      name: 'quantity',
      label: 'Кол-во',
      class: 'u-align-center',
      headerClass: 'u-align-center',
    },
    {
      name: 'price_base',
      label: 'Цена',
      class: 'u-align-center',
      headerClass: 'u-align-center',
    },
    {
      name: 'amount_base',
      label: 'Сумма',
      class: 'u-align-center',
      headerClass: 'u-align-center',
    },
    {
      name: 'price_discount',
      label: 'Скидка',
      class: 'u-align-center',
      headerClass: 'u-align-center',
    },
    {
      name: 'price',
      label: 'Итоговая цена',
      class: 'u-align-center',
      headerClass: 'u-align-center',
    },
    {
      name: 'amount',
      label: 'Итоговая сумма',
      class: 'u-align-center',
      headerClass: 'u-align-center',
    },
  ],

  searchItemsTableFields: {
    id: 'id',
    name: 'name',
    barcode: 'barcode',
    price: 'price',
  },

  searchItemsTableFilters: {
    catalog_id: [],
  },


  // Write-Offs
  writeOffPositionTableColumns: [
    {
      name: 'id',
      label: 'ID',
    },
    {
      name: 'name',
      label: 'Название товара',
    },
    {
      name: 'barcode',
      label: 'ШК товара',
    },
    {
      name: 'quantity',
      label: 'Кол-во',
      class: 'u-align-center',
      headerClass: 'u-align-center',
    },
    {
      name: 'price',
      label: 'Цена',
      class: 'u-align-center',
      headerClass: 'u-align-center',
    },
    {
      name: 'price_discount',
      label: 'Скидка',
      class: 'u-align-center',
      headerClass: 'u-align-center',
    },
    {
      name: 'total_price',
      label: 'Итоговая цена',
      class: 'u-align-center',
      headerClass: 'u-align-center',
    },
    {
      name: 'amount',
      label: 'Сумма',
      class: 'u-align-center',
      headerClass: 'u-align-center',
    },
  ],

  writeOffPositionTableFields: {
    id: 'id',
    payment_id: 'payment_id',
    client_id: 'client_id',
    timestamp_inserting: 'timestamp_inserting',
    store_name: 'store_name',
    items: 'items',
    status: 'status'
  },

  writeOffTableColumns: [
    {
      name: 'id',
      label: 'ID списания',
    },
    {
      name: 'payment_id',
      label: 'Базовая оплата ID',
    },
    {
      name: 'company_id',
      label: 'ID компании',
    },
    {
      name: 'client_id',
      label: 'ID клиента',
    },
    {
      name: 'timestamp_inserting',
      label: 'Дата создания',
      class: 'u-align-center',
      headerClass: 'u-align-center',
    },
    {
      name: 'amount',
      label: 'Сумма,₽',
      class: 'u-align-center',
      headerClass: 'u-align-center',
    },
    {
      name: 'status',
      label: 'Статус',
      class: 'u-align-center',
      headerClass: 'u-align-center',
    },
  ],

  writeOffTableFields: {
    id: 'id',
    payment_id: 'payment_id',
    client_id: 'client_id',
    timestamp_inserting: 'timestamp_inserting',
    items: 'items',
    status: 'status',
    support_comments: 'support_comments',
  },
  writeOffTableSorting: {
    id: '',
    payment_id: '',
  },
  writeOffTableFilters: {
    store_id: [],
    status: [],
    client_id: [],
    timestamp_inserting_start: '',
    timestamp_inserting_end: ''
  },

  // writeOffTableFilterOptions: [
  //   {
  //     name: 'store_id',
  //     label: 'Торговые точки',
  //     type: 'multiselect',
  //     inputPlaceholder: 'ID или название торговой точки',
  //     remoteMethod: busenessSectionsApi.getList({}, 'store')
  //   },
  //   {
  //     name: 'status',
  //     label: 'Статус заказа',
  //     type: 'checkbox',
  //     options: [],
  //   },
  //   {
  //     name: 'client_id',
  //     label: 'ID клиента',
  //     type: 'input',
  //     inputPlaceholder: 'ID клиента',
  //     inputType: 'number',
  //     options: [],
  //   },
  //   {
  //     name: 'timestamp_inserting',
  //     isSpecial: true,
  //     label: 'Дата создания',
  //     type: 'date',
  //     dateFormat: 'yyyy-MM-dd',
  //     options: [],
  //     filterStart: 'timestamp_inserting_start',
  //     filterEnd: 'timestamp_inserting_end',
  //   },
  // ],

  writeOffTablePagination: {
    totalItems: 0,
    totalPages: 1,
    page: 1,
    limit: 10,
  }
});

const state = initialState();
type TState = typeof state;

const getters = {
  searchItemsTableColumns: (state: TState) => state.searchItemsTableColumns,
  searchItemsTableFields: (state: TState) => state.searchItemsTableFields,
  searchItemsTableFilters: (state: TState) => state.searchItemsTableFilters,
  itemsTableColumns: (state: TState) => state.itemsTableColumns,
  writeOffPositionTableColumns: (state: TState) => state.writeOffPositionTableColumns,
  writeOffPositionTableFields: (state: TState) => state.writeOffPositionTableFields,
  writeOffTableColumns: (state: TState) => state.writeOffTableColumns,
  writeOffTableFields: (state: TState) => state.writeOffTableFields,
  writeOffTableSorting: (state: TState) => state.writeOffTableSorting,
  writeOffTableFilters: (state: TState) => state.writeOffTableFilters,
  // writeOffTableFilterOptions: (state: TState, getters, rootGetters) => {
  //   let statuses = rootGetters['orders$writeOffs'].writeOffStatuses;
  //   let statusOptionIndex = state.writeOffTableFilterOptions.findIndex(option => option.name === 'status');
  //   state.writeOffTableFilterOptions[statusOptionIndex].options = Object.entries(statuses).map(([key, value]) => ({
  //     name: value,
  //     value: key
  //   }));
  //   return state.writeOffTableFilterOptions;
  // },
  writeOffTablePagination: state => state.writeOffTablePagination,
};

const mutations = {
  setIsItemsTableColumnsCheckboxVisible: (state: TState, isCheckboxVisible: boolean) => {
    let issetCheckbox = !!state.itemsTableColumns.filter(column => column.name === 'checkbox').length;
    if (isCheckboxVisible && !issetCheckbox) {
      state.itemsTableColumns.unshift({
        name: 'checkbox',
        disableClickEvent: true,
      });
    }
    if (!isCheckboxVisible && issetCheckbox) {
      state.itemsTableColumns = state.itemsTableColumns.filter(column => column.name !== 'checkbox');
    }
  },
  setFilters: (state: TState, filters) => {
    state.writeOffTableFilters = filters;
  }
};

const actions = {
  setIsItemsTableColumnsCheckboxVisible: ({ commit }, isCheckboxVisible: boolean) => commit('setIsItemsTableColumnsCheckboxVisible', isCheckboxVisible),
  setFilters: ({commit}, filters: {}) => commit('setFilters', filters)
};


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
