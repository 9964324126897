<template>
  <HeaderContent
    v-if="showHeaderContent"
    :showButtons="['notifications', 'actionBtn']"
    :actionBtnText="headerButtons[curRouteName].label"
    @onAction="headerButtonsAction"
  />
  <HeaderContent v-else :showButtons="['notifications']" />

  <div class="data">
    <section v-if="maybeRouterView" class="section">
      <router-link v-for="item in mainPageItems" :key="item.id" :to="item.link" class="section-card">
        <div class="card-image"></div>
        <div class="card-label">
          {{ item.label }}
        </div>
      </router-link>
    </section>
    <router-view v-else :key="$route.fullPath" />
  </div>
</template>

<script>
import HeaderContent from '@/core/components/layouts/HeaderContent/HeaderContent.vue';
import { mapGetters } from 'vuex';

const headerButtons = {
  order: { action: 'redirect', label: 'Создать доплату' },
  charge: { action: 'redirect', label: 'Создать списание' }
};

export default {
  components: {
    HeaderContent,
  },

  computed: {
    ...mapGetters({
      mainPageItems: 'data$main/mainPageItems',
    }),

    showHeaderContent() {
      return this.headerButtons[this.curRouteName]?.label || false;
    },

    curRouteName() {
      const arraysOfRoutes = this.$route.name.split('$');
      return arraysOfRoutes[arraysOfRoutes.length - 1];
    },

    maybeRouterView() {
      return this.$route.path === '/data';
    },

    links() {
      const [_, __, curRoute] = this.$route.fullPath.split('/');
      const crumb = { title: this.mainPageItems.find((r) => r.route === curRoute).label };
      return [{ title: 'Данные' }, crumb];
    },
  },

  data() {
    return {
      headerButtons,
    };
  },

  methods: {
    headerButtonsAction() {
      this[this.headerButtons[this.curRouteName].action](this.curRouteName);
    },

    redirect(name) {
      console.log(name);
      if (name === 'charge') {
        this.$router.replace({ name: `data$writeOff$check` });
      } else {
        this.$router.replace({ name: `data$${name}$extra` });
      }

    },
  },
};
</script>

<style lang="scss" scoped>
.data {
  width: 100%;
  background-color: #f7fafc;
  padding-top: 0;
  height: 100%;
}
.section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.section-card {
  margin: 20px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 40px 64px;

  width: 208px;
  height: 209px;
  background: #f7fafc;

  border: 1px solid #dcdee6;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  .card-label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #191b23;
    text-decoration: none;
  }
}
</style>
