import { busenessSectionsApi } from '@/apps/business-sections/api';

type TWriteOffStatuses =
  'created'
  | 'review'
  | ' approved'
  | 'cancelled'
  | 'declined'
  | 'delete_review';
type TCreateWriteOffSteps = 'init' | 'result';

const initialState = () => ({
  //WriteOff
  writeOff: {
    support_comments: ''
  },
  writeOffs: [],
  writeOffStatuses: [],
  createWriteOffStep: 'init',

  //Order
  order: {},

  //Items
  items: [],
  searchItems: [],
  selectedToWriteOffItemsIDs: [],
  searchItemsString: '',
  selectedSearchItems: [],
  isSelectAllItems: false,

});

const state = initialState();
type TState = typeof state;

const getters = {
  //WriteOff
  writeOff: (state: TState) => state.writeOff,
  createWriteOffStep: (state: TState) => state.createWriteOffStep,
  writeOffs: (state: TState) => state.writeOffs,
  writeOffStatuses: (state: TState) => state.writeOffStatuses,

  //Order
  order: (state: TState) => state.order,

  //Items
  items: (state: TState) => state.items,
  searchItems: (state: TState) => state.searchItems,
  selectedToWriteOffItemsIDs: (state: TState) => state.selectedToWriteOffItemsIDs,
  searchItemsString: (state: TState) => state.searchItemsString,
  selectedSearchItems: (state: TState) => state.selectedSearchItems,
  isSelectAllItems: (state: TState, getters) => (getters.items.length === getters.selectedToWriteOffItemsIDs.length) && !!getters.selectedToWriteOffItemsIDs.length,
};

const mutations = {

  //WriteOff
  setWriteOff: (state: TState, writeOff) => state.writeOff = writeOff,
  setCreateWriteOffStep: (state: TState, createWriteOffStep: TCreateWriteOffSteps) => state.createWriteOffStep = createWriteOffStep,
  setWriteOffs: (state: TState, writeOffs) => state.writeOffs = writeOffs,
  setWriteOffStatuses: (state: TState, writeOffStatuses) => state.writeOffStatuses = writeOffStatuses,

  //Order
  setOrder: (state: TState, order: Object) => state.order = order,

  //Items
  setItems: (state: TState, items: []) => state.items = items,
  setSearchItems: (state: TState, searchItems: []) => {
    state.searchItems = [
      ...state.selectedSearchItems,
      ...searchItems.filter((item: Record <string, any>) => {
          return !state.selectedSearchItems
            .map(selectedItem => selectedItem.id)
            .includes(item.id);
        }
      )
    ];
  },
  setSelectedToWriteOffItemsIDs: (state: TState, selectedToWriteOffItemsIDs: []) => state.selectedToWriteOffItemsIDs = selectedToWriteOffItemsIDs,
  setSearchItemsString: (state: TState, searchItemsString: '') => state.searchItemsString = searchItemsString,
  setSelectedSearchItems: (state: TState, selectedSearchItems) => (state.selectedSearchItems = selectedSearchItems),
  setIsSelectAllItems: (state: TState, isSelectAllItems: boolean) => state.isSelectAllItems = isSelectAllItems,
};

const actions = {
  //WriteOff
  setWriteOff: ({ commit }, writeOff) => commit('setWriteOff', writeOff),
  setCreateWriteOffStep: ({ commit }, createWriteOffStep: TCreateWriteOffSteps) => commit('setCreateWriteOffStep', createWriteOffStep),
  setWriteOffs: ({ commit }, writeOffs) => commit('setWriteOffs', writeOffs),
  getWriteOffByID: ({ commit, rootGetters }, writeOffID) => {
    return busenessSectionsApi.getWriteOffById({
      id: writeOffID,
      fields: {
        ...rootGetters['data$writeOffTable/writeOffTableFields'],
        store_name: 'store_name',
        catalog_id: 'catalog_id',
        store_id: 'store_id',
        receipt_link: 'receipt_link',
        payment_error_text: 'payment_error_text'
      }
    })
      .then(res => {
        if (res.items[0]) {
          let preparedItems = res.items[0].items.map(item => ({
            item: {
              id: item.item.id,
              name: item.item_name,
              barcode: item.barcode
            },
            item_id: item.item.id,
            quantity: item.quantity,
            price: item.price,
            price_discount: 0
          }))
          commit('setWriteOff', res.items[0]);
          commit('setItems', preparedItems);
          commit('setSelectedToWriteOffItemsIDs', preparedItems.map(item => item.item.id))
        }

        return res.items[0];
      });
  },
  getWriteOffByOrderID: ({commit}, orderID) => {
    return busenessSectionsApi.getWriteOffs({
      fields: {
        id: 'id',
        status: 'status'
      },
      filters: {
        payment_id: orderID
      }
    })
  },
  getWriteOffStatuses: ({ commit }) => {
    return busenessSectionsApi.getWriteOffStatuses().then(statuses => {
      commit('setWriteOffStatuses', statuses);
      return statuses;
    });
  },
  getWriteOffs: ({commit}, params) => {
    return busenessSectionsApi.getList(params, 'charge');
  },
  clearWriteOffs: ({commit}) => commit('setWriteOffs', []),
  createWriteOff: ({commit}, payload) => {
    return busenessSectionsApi.createWriteOff(payload);
  },

  updateWriteOff: ({commit}, payload) => {
    return busenessSectionsApi.updateWriteOff(payload);
  },


  //Order
  getOrderById: async ({ commit }, payload) => {
    return busenessSectionsApi.getItemById({
      order_id: payload.order_id,
      route: 'order',
    }).then(result => {
      commit('setOrder', result);
      return result;
    });

  },
  checkPossibilityWriteOff: (orderID) => {

  },

  //Items
  getItems: ({ commit }, payload) => {
    return busenessSectionsApi.getList(payload.params, payload.route);
  },
  setItems: ({ commit }, items: []) => {
    commit('setItems', items);
  },
  setSearchItems: ({ commit }, searchItems: []) => commit('setSearchItems', searchItems),
  setSelectedToWriteOffItemsIDs: ({ commit }, selectedToWriteOffItemsIDs: []) => {
    commit('setSelectedToWriteOffItemsIDs', selectedToWriteOffItemsIDs);
  },
  setSearchItemsString: ({ commit }, searchItemsString: '') => commit('setSearchItemsString', searchItemsString),
  setSelectedSearchItems: ({ commit }, selectedSearchItems) => commit('setSelectedSearchItems', selectedSearchItems),
  setIsSelectedAllItems: ({ commit }, isSelectAllItems: boolean) => commit('setIsSelectAllItems', isSelectAllItems),
};


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
