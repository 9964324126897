import { IExtraApprove, IExtraReq, } from './types.api';
import { api } from '@brskl/core';

type possibleMode = 0 | 1;
interface IRefundCreate {
  parent_order_id?: number;
  items?: any[];
  mode?: possibleMode;
}

interface IRefundApprove {
  order_id?: number;
  pay_u_order_id?: number;
  mode?: possibleMode;
}

class BSectionsApi {
  public getMenuList = async () => {
    return api.endpoints.current.get({ path: 'dashboard/data/get-list' });
  };

  public getMeta = async (route: string) => {
    return api.endpoints.current.get({ path: `dashboard/data/${route}/get-fields-list` });
  };

  public getList = async (opts = {}, route: string) => {
    return api.endpoints.current.get({
      path: `dashboard/data/${route}/get-list`,
      params: opts,
    });
  };

  public getItemById = async ({ order_id = 0, route = '' }: { order_id?: number; route: string }) => {
    const params = {
      ...(order_id && { id: order_id }),
    };
    return api.endpoints.current.get({ path: `dashboard/data/${route}/get-by-id`, params });
  };

  public getWriteOffById = async payload => {
    return api.endpoints.current.get({ path: `dashboard/data/charge/get-by-id`, params: {charge_id: payload.id, fields: payload.fields} });
  };

  public getWriteOffs = payload => {
    return api.endpoints.current.get({ path: `dashboard/data/charge/get-list`, params: payload });
  };

  public getWriteOffStatuses = async () => {
    return api.endpoints.current.get({ path: `dashboard/data/charge/get-statuses`});
  };

  public createWriteOff = payload => {
    return api.endpoints.current.post({
      path: 'dashboard/data/charge/create',
      params: payload
    })
  };

  public updateWriteOff = payload => {
    return api.endpoints.current.post({
      path: 'dashboard/data/charge/update',
      params: payload
    })
  };

  public extraCreate = async (opts: IExtraReq) => {
    const { customer_id, store_id, items = [], mode } = opts;
    const path = 'dashboard/data/order/extra/create';
    return api.endpoints.current.post({
      path,
      params: {
        customer_id,
        store_id,
        items,
        mode,
      },
    });
  };

  public extraApprove = async (opts: IExtraApprove) => {
    const { order_id, mode } = opts;
    const path = 'dashboard/data/order/extra/pay-by-card';
    const params = { order_id, mode };
    return api.endpoints.current.post({ path, params });
  };

  public refundCreate = async (opts: IRefundCreate) => {
    const { parent_order_id, items, mode } = opts;
    return api.endpoints.current.post({
      path: `dashboard/data/order/refund/create`,
      params: { parent_order_id, items, mode },
    });
  };

  public refundApprove = async (opts: IRefundApprove = {}) => {
    const { order_id, pay_u_order_id, mode } = opts;
    return api.endpoints.current.post({
      path: `dashboard/data/order/refund/refund`,
      params: { order_id, pay_u_order_id, mode },
    });
  };
}

export const busenessSectionsApi = new BSectionsApi();
