import data from '@/apps/business-sections/views/BusinessSections.vue';

const breadCrumb = [
  {
    label: 'Данные',
    route: { name: 'data' },
  },
  {
    label: 'Магазины',
    route: { name: 'data$store' },
  },
  {
    label: 'Компании',
    route: { name: 'data$company' },
  },
  {
    label: 'Клиенты',
    route: { name: 'data$customer' },
  },
  {
    label: 'Товары',
    route: { name: 'data$item' },
  },
  {
    label: 'Заказы',
    route: { name: 'data$order' },
  },
  {
    label: 'Сотрудники',
    route: { name: 'data$employee' },
  },
  {
    label: 'Доставки',
    route: { name: 'data$delivery' },
  },
  {
    label: 'Каталоги',
    route: { name: 'data$catalogs' },
  },
  {
    label: 'Категории',
    route: { name: 'data$category' },
  },
  {
    label: 'Заказ',
    route: { name: 'bsections$order$order_id' },
  },
  {
    label: 'Создание доплаты',
    route: { name: 'data$order$extra' },
  },
  {
    label: 'Списания',
    route: { name: 'data$charge' }
  },
  {
    label: 'Списание :charge_id'
  },
  {
    label: 'Новое списание',
    route: { name: 'data$writeOff$create' },
  },
  {
    label: 'Проверка возможности списания'
  }
];

export default [
  {
    path: 'data',
    name: 'data',
    component: data,
    meta: {
      breadCrumb: [breadCrumb[0]],
    },

    children: [
      {
        path: '/data/store',
        name: 'data$store',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[1]],
        },
        component: () => import('@/apps/business-sections/views/Tables/Tables.vue'),
      },
      {
        path: '/data/company',
        name: 'data$company',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[2]],
        },
        component: () => import('@/apps/business-sections/views/Tables/Tables.vue'),
      },
      {
        path: '/data/customer',
        name: 'data$customer',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[3]],
        },
        component: () => import('@/apps/business-sections/views/Tables/Tables.vue'),
      },
      {
        path: '/data/item',
        name: 'data$item',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[4]],
        },
        component: () => import('@/apps/business-sections/views/Tables/Tables.vue'),
      },
      {
        path: '/data/order',
        name: 'data$order',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[5]],
        },

        component: () => import('@/apps/business-sections/views/Tables/Tables.vue'),
      },
      {
        path: '/data/employee',
        name: 'data$employee',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[6]],
        },
        component: () => import('@/apps/business-sections/views/Tables/Tables.vue'),
      },
      {
        path: '/data/delivery',
        name: 'data$delivery',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[7]],
        },
        component: () => import('@/apps/business-sections/views/Tables/Tables.vue'),
      },
      {
        path: '/data/category',
        name: 'data$category',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[9]],
        },
        component: () => import('@/apps/business-sections/views/Tables/Tables.vue'),
      },
      {
        path: '/data/catalog',
        name: 'data$catalog',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[8]],
        },
        component: () => import('@/apps/business-sections/views/Tables/Tables.vue'),
      },
      {
        path: '/data/charge',
        name: 'data$charge',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[12]],
        },
        component: () => import('@/apps/business-sections/views/Tables/Tables.vue'),
      },
    ],
  },
  {
    path: '/data/charge/check',
    name: 'data$writeOff$check',
    component: () => import('@/apps/business-sections/views/WriteOff/CheckPossibilityWriteOff/CheckPossibilityWriteOff.vue'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[12], breadCrumb[15]],
    },
  },
  {
    path: '/data/charge/:charge_id',
    name: 'data$writeOff',
    component: () => import('@/apps/business-sections/views/WriteOff/CreateWriteOff/CreateWriteOff.vue'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[12], breadCrumb[13]],
    },
  },
  {
    path: '/data/order/:order_id/charge/create',
    name: 'data$writeOff$create',
    component: () => import('@/apps/business-sections/views/WriteOff/CreateWriteOff/CreateWriteOff.vue'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[12], breadCrumb[14]],
    },
  },
  {
    path: '/data/order/:order_id',
    name: 'data$order$order_id',
    component: () => import('@/apps/business-sections/views/Order/Order.vue'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[5], breadCrumb[10]],
    },
  },
  {
    path: '/data/order/extra',
    name: 'data$order$extra',
    component: () => import('@/apps/business-sections/views/Order/ExtraPayment/ExtraPayment.vue'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[5], breadCrumb[11]],
    },
  },
];

